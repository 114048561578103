import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TechBackground from './TechBackground';
import { Typography, Container } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.main
    },
    text: {
        color: 'rgba(0,0,0,0.7)',
        fontSize: '18px',
        lineHeight: '180%'
    },
    lowerBanner: {
        height: '300px',
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'center',
        backgroundSize: 'cover',
    }
}));

function Presentation(props) {
    const classes = useStyles();
    return (
    <div>
        <TechBackground>
            <Container maxWidth="lg">
                <Typography variant="h3" className={classes.title}>
                    {props.data.title}
                </Typography>
                <Typography className={classes.text}>
                    <span dangerouslySetInnerHTML={{__html: props.data.description}} />
                </Typography>
            </Container>
        </TechBackground>
        <div
            className={classes.lowerBanner}
            style={{ backgroundImage: `url(${props.lowerBanner})` }}
        />
    </div>
    )
}

export default Presentation;