import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import TextPlaceholder from './TextPlaceholder';


const useStyles = makeStyles(theme => ({
    mainContainer: {
        backgroundColor: theme.palette.primary.main,
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8)
    },
    text: {
        color: 'rgba(255,255,255,0.8)',
        lineHeight: '180%',
        fontSize: '16px',
        marginBottom: theme.spacing(2)       
    },
    icon: {
        position: 'relative',
        top: '3px'
    },
    subtitle: {
        color: 'white',
        fontWeight: 'bold'
    }
}));

function CostumerPlaceholder(){
    const classes = useStyles()

    return (
    <div className={classes.mainContainer}>
        <Container maxWidth="md" >
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h3" className={classes.subtitle} noWrap>
                        <TextPlaceholder lines={1} />
                    </Typography>
                    <Typography className={classes.text}>
                        <TextPlaceholder lines={3} />
                    </Typography>
                    {[...Array(4)].map((_, key) => (
                    <React.Fragment key={key}>
                        <Typography variant="h5" className={classes.subtitle} noWrap>
                            <TextPlaceholder lines={1} />
                        </Typography>
                        <Typography gutterBottom variant="body2" className={classes.text}>
                            <TextPlaceholder lines={3} />
                        </Typography>
                    </React.Fragment>
                    ))}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h3" className={classes.subtitle} noWrap>
                        <TextPlaceholder lines={1} />
                    </Typography>
                    <Typography className={classes.text}>
                        <TextPlaceholder lines={3} />
                    </Typography>
                    {[...Array(4)].map((_, key) => (
                    <React.Fragment key={key}>
                        <Typography variant="h5" className={classes.subtitle} noWrap>
                            <TextPlaceholder lines={1} />
                        </Typography>
                        <Typography gutterBottom variant="body2" className={classes.text}>
                            <TextPlaceholder lines={3} />
                        </Typography>
                    </React.Fragment>
                    ))}
                </Grid>
            </Grid>
        </Container>
    </div>
    )
}

export default CostumerPlaceholder