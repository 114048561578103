import { makeStyles } from "@material-ui/core";
import React, { memo } from "react";

const useStyles = makeStyles(theme => ({
    ...theme.placeholderAnimation,
    container: {
        ...theme.placeholder,
		borderRadius: theme.shape.borderRadius,
		color: "transparent",
		display: "flex",
        flexGrow: 1,
        marginBottom: theme.spacing(1)
    }
}));

function Component(props) {
    const classes = useStyles();

    if (props.lines !== undefined) {
        const { lines } = props;
        if (lines === 0) return (<></>)
        return (
        <span className={classes.container}>
            {[...Array(lines)].map((_, i) => (
                <br key={i} />
            ))}
        </span>
        );
    }

    const { characters } = props;
    return (
    <span className={classes.container}>
        {"_ ".repeat(characters)}
    </span>
    );    
}

const TextPlaceholder = memo(Component, () => true);

export default TextPlaceholder;