import TeamviewerIcon from 'mdi-react/TeamviewerIcon';
import PrinterIcon from 'mdi-react/PrinterIcon';
import DatabaseIcon from 'mdi-react/DatabaseIcon';
import AppsIcon from 'mdi-react/AppsIcon';
import ScaleIcon from 'mdi-react/ScaleIcon';
import QrcodeIcon from 'mdi-react/QrcodeIcon';
import ToolboxIcon from 'mdi-react/ToolboxIcon';

const parseLinks = links => [
    {
        category: "Team Viewer",
        icon: TeamviewerIcon,
        items: links.remoteAcess
    },
    {
        category: "Drivers de impressoras",
        icon: PrinterIcon,
        items: links.printers
    },
    {
        category: "Banco de dados",
        icon: DatabaseIcon,
        items: links.databases
    },
    {
        category: "Aplicações",
        icon: AppsIcon,
        items: links.applications
    },
    {
        category: "Balanças",
        icon: ScaleIcon,
        items: links.scales
    },
    {
        category: "Fiscal",
        icon: QrcodeIcon,
        items: links.accountability
    },
    {
        category: "Outros sistemas",
        icon: AppsIcon,
        items: links.systems
    },
    {
        category: "Acessórios",
        icon: ToolboxIcon,
        items: links.acessories
    },
];

export default parseLinks;