import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Slider from './components/Slider';
import Footer from './components/Footer';
import Support from './components/Support';
import Presentation from './components/Presentation';
import { ThemeProvider } from '@material-ui/core';
import theme from './theme'
import Costumers from './components/Costumers';
import Questions from './components/Questions';
import parseLinks from './links';
import { HashRouter, Switch, Route } from 'react-router-dom';
import Manual from './components/Manual'
import NavbarPlaceholder from './placeholders/NavbarPlaceholder';
import SliderPlaceholder from './placeholders/SliderPlaceholder';
import SupportPlaceholder from './placeholders/SupportPlaceholder';
import PresentationPlaceholder from './placeholders/PresentationPlaceholder';
import CostumersPlaceholder from './placeholders/CostumersPlaceholder';
import QuestionsPlaceholder from './placeholders/QuestionsPlaceholder';


function App() {

	const [ supportDialogOpenned, setSupportDialogOpenned ] = useState(false);
	const [ data, setData ] = useState({})
	const [ loading, setLoading ] = useState(true)

	useEffect(() => {
        fetch(`https://clickapi.com.br/web/site`)
        .then(response => response.json())
		.then(requestData => setData({
			...requestData,
			downloadSearch: parseLinks(requestData.downloadSearch)
		}))
		.then(() => setLoading(false))
	}, [])
	
	return (
	<HashRouter>
		<ThemeProvider theme={theme}>
			{loading ? (
			<div>
				<NavbarPlaceholder />
				<SliderPlaceholder />
				<SupportPlaceholder />
				<PresentationPlaceholder />
				<CostumersPlaceholder />
				<QuestionsPlaceholder />
				<Footer />
			</div>
			) : (
			<div>
				<Navbar
					downloadLinks={data.downloadSearch}
					filialSupport={data.filialSupport}
					supportDialogOpenned={supportDialogOpenned}
					openSupportDialog={() => setSupportDialogOpenned(true)}
					closeSupportDialog={() => setSupportDialogOpenned(false)}
				/>
				<Switch>
					<Route path="/manual" component={Manual} />
					<Route render={() => (
						<React.Fragment>
							<Slider items={data.banner} />
							<Support
								PDVDownloadLink={data.mainDownload}
								supportDialogOpenned={supportDialogOpenned}
								openSupportDialog={() => setSupportDialogOpenned(true)}
								closeSupportDialog={() => setSupportDialogOpenned(false)}
								data={data.support}
							/>
							<Presentation
								data={data.presentation}
								lowerBanner={data.innerBanner}
							/>
							<Costumers
								restaurants={data.restaurants}
								stores={data.stores}
							/>
							<Questions
								data={data.questions}
							/>
						</React.Fragment>
					)} />
				</Switch>
				<Footer />
			</div>
			)}
		</ThemeProvider>
	</HashRouter>
	)
}

export default App;
