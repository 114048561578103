import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TechBackground from '../components/TechBackground';
import { Typography, Container } from '@material-ui/core';
import TextPlaceholder from './TextPlaceholder';

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
        color: theme.palette.primary.main
    },
    text: {
        color: 'rgba(0,0,0,0.7)',
        fontSize: '18px',
        lineHeight: '180%'
    },
    ...theme.placeholderAnimation,
    lowerBanner: {
        ...theme.placeholder,
        height: '300px',
        width: '100%',
    }
}));

function PresentationPlaceholder() {
    const classes = useStyles();
    return (
    <div>
        <TechBackground>
            <Container maxWidth="lg">
                <Typography variant="h3" className={classes.title}>
                    <TextPlaceholder lines={1} />
                </Typography>
                <Typography className={classes.text}>
                    <TextPlaceholder lines={8}/>
                </Typography>
            </Container>
        </TechBackground>
        <div className={classes.lowerBanner} />
    </div>
    )
}

export default PresentationPlaceholder;