import { createMuiTheme } from '@material-ui/core/styles'
const placeholderOpacity = 0.1;

export default createMuiTheme({
    placeholderAnimation: {
        "@keyframes placeholderAnimation": {
            "0%": {
                backgroundPosition: "-468px 0"
            },
            "100%": {
                backgroundPosition: "468px 0"
            }
        },
    },
    placeholder: {
        animationDuration: "1.25s",
        animationFillMode: "forwards",
        animationIterationCount: "infinite",
        animationName: "$placeholderAnimation",
        animationTimingFunction: "linear",
        backgroundSize: '936px 1px',
        backgroundColor: `rgba(0, 0, 0, ${placeholderOpacity})`,
        background: `linear-gradient(to right, rgba(0, 0, 0, ${placeholderOpacity}) 10%, rgba(0, 0, 0, ${placeholderOpacity+0.05}) 18%, rgba(0, 0, 0, ${placeholderOpacity}) 33%)`
    },
    palette: {
        primary: {
            main: '#48AEBC',
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: '#FFFFFF',
            contrastText: '#48AEBC'
        },
    }
})