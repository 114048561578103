import React from 'react';
import { Dialog, DialogTitle, DialogContent, List, ListItem, Button, DialogActions, ListItemIcon, ListItemText } from '@material-ui/core'
import StoreIcon from 'mdi-react/StoreIcon';

function SupportDialog(props) {
    return (
    <Dialog onClose={props.close} open={props.open}>
        <DialogTitle onClose={props.close}>
            Escolha uma filial para ser atendido
        </DialogTitle>
        <DialogContent dividers>
            <List>
                {props.filials.map((item, index) => (
                <ListItem key={index} button component="a" href={item.link} target="_blank">
                    <ListItemIcon>
                        <StoreIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={item.title}
                    />
                </ListItem>
                ))}
            </List>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.close}>
                Fechar
            </Button>
        </DialogActions>
    </Dialog>
    )
}

export default SupportDialog;