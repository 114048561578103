import React from 'react';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    ...theme.placeholderAnimation,
    container: {
        ...theme.placeholder,
        backgroundColor: 'white',
        height: theme.spacing(60),
        position: 'relative',
        display: 'flex',
    },
}));

function SliderPlaceholder() {
    const classes = useStyles();
    return (
    <div className={classes.container} />
    )
}

export default SliderPlaceholder;