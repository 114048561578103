import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import TechBackground from './TechBackground';

const useStyles = makeStyles(theme => ({
    mainContainer: {
        backgroundColor: theme.palette.primary.main
    },
    bold: {
        fontWeight: 'bold'
    },
    text: {
        color: 'rgba(0,0,0,0.6)',
    }
}));

function Questions(props){
    const classes = useStyles()

    return (
    <TechBackground>
        <Container maxWidth="md" >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography color="primary" variant="h3" className={classes.bold}>
                        Dúvidas
                    </Typography>
                </Grid>
                {props.data.map((question, key) => (
                <Grid item xs={12} sm={6} key={key}>
                    <Typography variant="h6" className={classes.bold}>
                        {question.title}
                    </Typography>
                    <Typography variant="body2" className={classes.text}>
                        <span dangerouslySetInnerHTML={{__html: question.description}} />
                    </Typography>
                </Grid>
                ))}
            </Grid>
        </Container>
    </TechBackground>
    )
}

export default Questions