import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import SilverwareForkKnifeIcon from 'mdi-react/SilverwareForkKnifeIcon';
import StoreIcon from 'mdi-react/StoreIcon';
import CursorDefaultClickIcon from 'mdi-react/CursorDefaultClickIcon';


const useStyles = makeStyles(theme => ({
    mainContainer: {
        backgroundColor: theme.palette.primary.main,
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8)
    },
    text: {
        color: 'rgba(255,255,255,0.8)',
        lineHeight: '180%',
        fontSize: '16px',
        marginBottom: theme.spacing(2)       
    },
    icon: {
        position: 'relative',
        top: '3px'
    },
    subtitle: {
        color: 'white',
        fontWeight: 'bold'
    }
}));

function Costumer(props){
    const classes = useStyles()

    return (
    <div className={classes.mainContainer}>
        <Container maxWidth="md" >
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h3" className={classes.subtitle} noWrap>
                        <StoreIcon className={classes.icon} size={40}/> {props.stores.title}
                    </Typography>
                    <Typography className={classes.text}>
                        {props.stores.description}
                    </Typography>
                    {props.stores.items.map((item, key) => (
                    <React.Fragment key={key}>
                        <Typography variant="h5" className={classes.subtitle} noWrap>
                            <CursorDefaultClickIcon className={classes.icon} size={28}/> {item.title}
                        </Typography>
                        <Typography gutterBottom variant="body2" className={classes.text}>
                            {item.description}
                        </Typography>
                    </React.Fragment>
                    ))}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h3" className={classes.subtitle} noWrap>
                        <SilverwareForkKnifeIcon className={classes.icon} size={40} /> {props.restaurants.title}
                    </Typography>
                    <Typography className={classes.text}>
                        {props.restaurants.description}
                    </Typography>
                    {props.restaurants.items.map((item, key) => (
                    <React.Fragment key={key}>
                        <Typography variant="h5" className={classes.subtitle} noWrap>
                            <CursorDefaultClickIcon className={classes.icon} size={28}/> {item.title}
                        </Typography>
                        <Typography gutterBottom variant="body2" className={classes.text}>
                            {item.description}
                        </Typography>
                    </React.Fragment>
                    ))}
                </Grid>
            </Grid>
        </Container>
    </div>
    )
}

export default Costumer