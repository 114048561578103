import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ChevronRight from 'mdi-react/ChevronRightIcon';
import ChevronLeft from 'mdi-react/ChevronLeftIcon';
import CircleMedium from 'mdi-react/CircleMediumIcon';
import clsx from 'clsx'
import { IconButton, Box, Fade } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        backgroundColor: theme.palette.grey[700],
        height: theme.spacing(60),
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: 'center',
        backgroundPositionY: 'top',
        backgroundSize: 'cover',
        transition: 'background-image 0.5s ease-in-out'
    },
    textContainer: {
        display: 'flex',
        justifyContent: 'flex-start'
    }, 
    arrowHover: {
        position: 'absolute',
        display: 'flex',
        top: 0,
        alignItems: 'center',
        opacity: 0,
        height: theme.spacing(60),
        width: '50%',
        transition: 'opacity 0.25s ease-in-out'
    },
    leftArrow: {
        left: 0,
        paddingLeft: theme.spacing(4),
        justifyContent: 'flex-start',
        '&:hover': {
            opacity: 1,
            background: 'linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)'
        }
    },
    rightArrow: {
        right: 0,
        paddingRight: theme.spacing(4),
        justifyContent: 'flex-end',
        '&:hover': {
            opacity: 1,
            background: 'linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)'
        }
    },
    dotsContainer: {
        position: 'absolute',
        display: 'flex',
        bottom: theme.spacing(4),
        alignItems: 'center',
        justifyContent: 'center'
    },
    dot: {
        transition: 'color 0.25s ease-in-out',
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    activeDot: {
        color: theme.palette.primary.main
    },
    unactiveDot: {
        color: 'white'
    },
    primaryText: {
        color: theme.palette.primary.contrastText,
        fontWeight: 900,
        fontSize: '48px',
        textShadow: '2px 2px 8px #000000'
    },
    secondaryText: {
        color: theme.palette.primary.contrastText,
        fontWeight: 500,
        fontSize: '24px',
        textShadow: '2px 2px 8px #000000'
    }
}));

function Slider(props) {
    const classes = useStyles();
    const [ selected, setSelected ] = useState(0);
    const next = () => setSelected(selected === props.items.length-1 ? 0 : selected+1) 
    const previous = () => setSelected(!selected ? props.items.length-1 : selected-1)
    useEffect(() => {
        const interval = setInterval(next, 5000)
        return () => clearInterval(interval)
    })
    
    return (
    <div className={classes.container} style={{
        backgroundImage: `url('${props.items[selected].image}')`
    }}>
        <div className={clsx({
            [classes.leftArrow]: true,
            [classes.arrowHover]: true
        })}>
            <IconButton onClick={previous}>
                <ChevronLeft color={'white'} size={100} />
            </IconButton> 
        </div>
        <div className={clsx({
            [classes.rightArrow]: true,
            [classes.arrowHover]: true
        })}>
            <IconButton onClick={next}>
                <ChevronRight color={'white'} size={100} />
            </IconButton>
        </div>
        <div className={classes.textContainer}>
            {props.items.map((item, index) => (
            <div key={index}>
                <Fade
                    in={index === selected}
                    timeout={{
                        enter: 1000,
                        exit: 0
                    }}
                    unmountOnExit
                    mountOnEnter
                >
                    <Box className={classes.primaryText}>
                        {item.primary}
                    </Box>
                </Fade>
                <Fade
                    in={index === selected}
                    timeout={{
                        enter: 1000,
                        exit: 0
                    }}
                    unmountOnExit
                    mountOnEnter
                >
                    <Box className={classes.secondaryText}>
                        {item.secondary}
                    </Box>
                </Fade>
            </div>
            ))}
        </div>
        <div className={classes.dotsContainer}>
            {props.items.map((item, index) => (
            <CircleMedium
                key={index}
                onClick={() => setSelected(index)}
                className={clsx({
                    [classes.dot]: true,
                    [classes.activeDot]: index === selected,
                    [classes.unactiveDot]: index !== selected
                })}
            />
            ))}
        </div>
    </div>
    )
}

export default Slider;