import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid, Card, CardActionArea, Link } from '@material-ui/core';
import whiteLogo from '../img/white-logo.svg';
import PhoneIcon from 'mdi-react/PhoneIcon';
import WhatsappIcon from 'mdi-react/WhatsappIcon';
import EmailIcon from 'mdi-react/EmailIcon';
import InstagramIcon from 'mdi-react/InstagramIcon';
import FacebookIcon from 'mdi-react/FacebookIcon';
import googleMaps from '../img/maps.png';

const useStyles = makeStyles(theme => ({
    copyright: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2), 
        backgroundColor: 'white'
    },
    infoContainer: {
        backgroundColor: theme.palette.primary.main,
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4)
    },
    whiteLogo: {
        width: '100%',
        maxWidth: theme.spacing(60)
    },
    map: {
        width: '100%',
        objectFit: 'cover'
    },
    mapContainer: {
        display: 'inline-block',
        margin: '0 auto',
        padding: theme.spacing(1),
    },
    subtitle: {
        fontWeight: 'bold',
        fontSize: '14px'
    }
}))

function Footer() {
    const classes = useStyles();
	return (
    <footer>
        <div className={classes.infoContainer}>
            <Container>
                <Grid container direction="row-reverse" spacing={4}>
                    <Grid item xs={12} md={6} lg={4} style={{ display: 'flex' }}>
                        <Card className={classes.mapContainer}>
                            <CardActionArea
                                component="a"
								target="_blank"
								rel="noopener noreferrer"
								href="https://g.page/Clicksi?share"
                            >
                                <img alt="Click Soluções Inteligentes" src={googleMaps} className={classes.map} />
                            </CardActionArea>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4} style={{ display: 'flex' }}>
                        <div style={{ display: 'inline-block', margin: '0 auto' }}>
                            <Typography color="secondary" variant="h6" className={classes.subtitle}>Paraty - RJ</Typography>
                            <Typography variant="caption" color="secondary">
                                <PhoneIcon size={10} /> 24 3512-0090 {" "}
                                <Link
                                    color="secondary"
                                    component="a"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://api.whatsapp.com/send?l=pt_pt&phone=5524998571651"
                                >
                                    <WhatsappIcon size={10} /> 24 99857-1651
                                </Link><br />
                                <EmailIcon size={10} /> contato@clicksi.com.br
                            </Typography>
                            <Typography color="secondary" variant="h6" className={classes.subtitle}>Limeira - SP</Typography>
                            <Typography variant="caption" color="secondary">
                                {/* <PhoneIcon size={10} /> 19 3704-0670 {" "} */}
                                <Link
                                    color="secondary"
                                    component="a"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://api.whatsapp.com/send?l=pt_pt&phone=5519997669314"
                                >
                                    <WhatsappIcon size={10} /> 19 99766-9314
                                </Link><br />
                                <EmailIcon size={10} /> contato@clicksi.com.br
                            </Typography>
                            <Typography color="secondary" variant="h6" className={classes.subtitle}>Siga nossas redes sociais</Typography>
                            <Typography variant="caption" color="secondary">
                                <Link
                                    color="secondary"
                                    component="a"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.facebook.com/clicksi1/"
                                >
                                    <FacebookIcon size={10} /> Facebook
                                </Link> {" "}
                                <Link
                                    color="secondary"
                                    component="a"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.instagram.com/somos.clicksi/"
                                >
                                    <InstagramIcon size={12} /> Instagram
                                </Link>
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={4} style={{ textAlign: 'center' }}>
                        <img src={whiteLogo} alt={"Click Soluções Inteligentes"} className={classes.whiteLogo} />
                    </Grid>
                    
                </Grid>
            </Container>
        </div>
        <Container maxWidth="lg"  className={classes.copyright}>
            <Typography align="center" color="primary">
                Click Soluções Inteligentes | 2019
            </Typography>
        </Container>
    </footer>
    );
}

export default Footer;
