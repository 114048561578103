import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Button, Typography } from '@material-ui/core';
import WebIcon from 'mdi-react/WebIcon'
import HeadsetIcon from 'mdi-react/HeadsetIcon'
import DownloadIcon from 'mdi-react/DownloadIcon'
import CashRegisterIcon from 'mdi-react/CashRegisterIcon'
import TextPlaceholder from './TextPlaceholder';

const useStyles = makeStyles(theme => ({
    mainContainer: {
        backgroundColor: theme.palette.primary.main,
        paddingBottom: theme.spacing(8)
    },
    overlayButton: {
        position: 'relative',
        top: -theme.spacing(2),
        fontWeight: 'bold',
        fontSize: '24px'
    },
    bold: {
        fontWeight: 'bold'
    }
}));

function SupportPlaceholder() {
    const classes = useStyles()

    return (
    <section className={classes.mainContainer}>
        <Container maxWidth="md">
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    <Button
                        startIcon={<CashRegisterIcon />}
                        className={classes.overlayButton}
                        size="large"
                        variant="contained"
                        color="secondary"
                        component="a"
                        fullWidth
                    >
                        Click PDV
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                        startIcon={<WebIcon />}
                        className={classes.overlayButton}
                        size="large"
                        variant="contained"
                        color="secondary"
                        component="a"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://web.clicksi.com.br"
                        fullWidth
                    >
                        Click Web
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" className={classes.bold} align="center" color="secondary">Precisa de suporte?</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h4" color="secondary" align="right" className={classes.bold}>
                        <TextPlaceholder lines={1} />
                    </Typography>
                    <Typography gutterBottom variant="body2"  align="right" color="secondary">
                        <TextPlaceholder lines={3} />
                    </Typography>
                    <Typography align="right">
                        <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            startIcon={<HeadsetIcon />}
                        >
                            Acessar
                        </Button>
                    </Typography>
                </Grid> 
                <Grid item xs={12} sm={6}>
                    <Typography variant="h4" color="secondary" className={classes.bold}>
                        <TextPlaceholder lines={1} />
                    </Typography>
                    <Typography gutterBottom variant="body2" color="secondary">
                        <TextPlaceholder lines={3} />
                    </Typography>
                    <Typography>
                        <Button
                            variant="contained"
                            size="small"
                            color="secondary"
                            startIcon={<DownloadIcon />}
                            component="a"
                            href="https://www.clicksi.com.br/programas/teamviewerqs.exe"
                        >
                            Download
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    </section>
    )
}

export default SupportPlaceholder