import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route, withRouter, Link as RouterLink } from 'react-router-dom';
import { Typography, Container, Divider, Link, LinearProgress, CircularProgress } from '@material-ui/core';
import WrenchIcon from 'mdi-react/WrenchIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    link: {
        color: theme.palette.primary.main,
        textDecoration: "underline"
    },
    disabledLink: {
        color: "#666",
        textDecoration: "underline"
    },
    icon: {
        marginLeft: "5px"
    },
    container: {
        padding: "30px 0px"
    },
    breadcrumbContainer: {
        width: '100%',
        overflowX: 'auto',
        marginTop: '20px'
    },
    circularProgress: {
        display: 'flex',
        margin: "0 auto",
        justifyContent: 'center',
        padding: "100px 0px"
    }
}))

function Breadcrumbs(props) {
    const classes = useStyles();
	return (
    <div className={classes.breadcrumbContainer}>
        {props.loading ? (
            <LinearProgress />
        ) : (
            props.items.map((item, index) => (
            <React.Fragment key={index}>
                <Link
                    component={RouterLink}
                    to={item.link}
                >
                    {item.label}
                </Link>
                {index !== props.items.length-1 && <ChevronRightIcon
                    size={12}
                    color={grey[400]}
                />}
            </React.Fragment>
            ))
        )}
    </div>
    );
}


function LinkList(props) {
    const classes = useStyles();
    return props.loading ? (
    <div className={classes.circularProgress}>
        <CircularProgress />
    </div>
    ) : (
    <ul>
        {props.items.map((item, index) => (
        <li key={index}>
            {item.label[0] === "#" ? (
            <span className={classes.disabledLink}>
                {item.label.substring(1)}<WrenchIcon size={12} className={classes.icon} />
            </span>
            ) : (
            <Link component={RouterLink} to={item.link} className={classes.link}>
                {item.label}
            </Link>
            )}
        </li>
        ))}
    </ul>
    )
}

function Sections(props) {
    const [ loading, setLoading ] = useState(true);
    const [ data, setData ] = useState([]);

    useEffect(() => {
        fetch(`https://clickapi.com.br/web/manual/sections`)
        .then(response => response.json())
        .then(results => setData(results.map(item => ({
            id: item.id,
            label: item.name,
            link: `/manual/${item.id}${props.location.search}`
        }))))
        .then(() => setLoading(false))
    }, [ props.location.search ]);

    return (
    <React.Fragment>
        <Breadcrumbs
            loading={loading}
            items={[{
                label: "Assuntos",
                link: `/manual${props.location.search}`
            }]}
        />
        <LinkList
            loading={loading}
            items={data}
        />
    </React.Fragment>
    );
}

function Subsections(props) {

    const [ loading, setLoading ] = useState(true);
    const [ data, setData ] = useState({info: {}, items: []});

    useEffect(() => {
        fetch(`https://clickapi.com.br/web/manual/sections/${props.match.params.sectionid}/subsections`)
        .then(response => response.json())
        .then(results => setData({
            section: results.info.name,
            items: results.cards.map(card => ({
                id: card.id,
                label: card.name,
                link: `/manual/${props.match.params.sectionid}/${card.id}${props.location.search}`
            }))
        }))
        .then(() => setLoading(false))
    }, [ props.match.params.sectionid, props.location.search ]);

    return (
    <React.Fragment>
        <Breadcrumbs 
            loading={loading}
            items={[{
                label: "Assuntos",
                link: `/manual${props.location.search}`
            },
            {
                label: data.section,
                link: `/manual/${props.match.params.sectionid}${props.location.search}`
            }]}
        />
        <LinkList
            loading={loading}
            items={data.items}
        />
    </React.Fragment>
    );
}

function Content(props) {

    const classes = useStyles();
    const parseText = (text, attachments) => attachments.reduce((acc, el) => acc.replace(new RegExp("&&"+el.name, 'g'), `<div style="text-align: center !important"><a target="_blank" href='${el.url}'><img style="border: 2px solid #DDD; border-radius: 5px; max-width: 100% !important; margin: 30px 0px; max-height: 600px;" src='${el.url}' /></a></div>`), text);
    const [ loading, setLoading ] = useState(true);
    const [ data, setData ] = useState({});

    useEffect(() => {
        fetch(`https://clickapi.com.br/web/manual/sections/${props.match.params.sectionid}/subsections/${props.match.params.subsectionid}/content`)
        .then(response => response.json())
        .then(results => setData({
            content: parseText(results.data.desc, results.attachments),
            title: results.data.name,
            section: results.list.name
        }))
        .then(() => setLoading(false))
    }, [ props.match.params.sectionid, props.match.params.subsectionid ]);

    return (
    <React.Fragment>
        <Breadcrumbs
            loading={loading}
            items={[{
                label: "Assuntos",
                link: `/manual${props.location.search}`
            },{
                label: data.section,
                link: `/manual/${props.match.params.sectionid}${props.location.search}`
            },{
                label: data.title,
                link: `/manual/${props.match.params.sectionid}/${props.match.params.subsectionid}${props.location.search}`
            }]}
        />
        {loading ? (
        <div className={classes.circularProgress}>
            <CircularProgress />
        </div>
        ) : (
        <div className={classes.contentContainer}>
            <Typography
                color="primary"
                variant="h5"
                style={{ marginTop: "30px" }}
            >
                {data.title}
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </div>
        )}
    </React.Fragment>
    );
}

function Manual(props) {
    const classes = useStyles();

	return (
    <Container className={classes.container} maxWidth="md">
        {props.location.search === "?e752e7a099b40ed0579f4911788850a3" ? (
        <React.Fragment>
            <Typography color="primary" variant="h3">
                Manual do usuário Click
            </Typography>
            <Divider className={classes.divider} />
            <Switch>
                <Route exact path="/manual" component={withRouter(Sections)} />
                <Route exact path="/manual/:sectionid" component={withRouter(Subsections)} />
                <Route exact path="/manual/:sectionid/:subsectionid" component={withRouter(Content)} />
            </Switch>
        </React.Fragment>
        ) : (
        <Typography color="primary" variant="h3">
            404 - A página que você está procurando não existe
        </Typography>
        )}
    </Container>
    );
}

export default withRouter(Manual);
