import React, { useState } from 'react'
import { fade, makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Container, InputBase, Button, Hidden } from '@material-ui/core';
import whiteLogo from '../img/white-logo.svg'
import compactLogo from '../img/compact-logo.svg'
import SearchIcon from 'mdi-react/SearchIcon';
import HeadsetIcon from 'mdi-react/HeadsetIcon';
import SupportDialog from './SupportDialog';
import DownloadDialog from './DownloadDialog';

const useStyles = makeStyles(theme => ({
    logo: {
        height: theme.spacing(10),
        paddingRight: theme.spacing(2)
    },
    container: {
        height: theme.spacing(14),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '180px',
        display: 'inline-block',
        marginRight: theme.spacing(1)
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        width: '100%',
    },
    root: {
        marginBottom: theme.spacing(14)
    }
}));

function Navbar(props){
    const classes = useStyles()
    const [ searchState, setSearchState ] = useState({ text: '', open: false, search: '' });

    const handleSearchChange = event => {
        event.persist();
        setSearchState(old => ({
            ...old,
            text: event.target.value
        }));
    }

    const handleSearchEnter = event => {
        event.persist();
        if (event.key === 'Enter') {
            event.target.blur();
            setSearchState(old => ({
                text: '',
                open: true,
                search: old.text
            }));
        }
    }

    return (
    <div className={classes.root}>
        <AppBar component="nav">
            <Toolbar>
                <Container className={classes.container} maxWidth='md'>
                    <Hidden xsDown>
                        <img
                            alt={"Click Soluções Inteligentes"}
                            className={classes.logo}
                            src={whiteLogo}
                        />
                    </Hidden>
                    <Hidden smUp>
                        <img
                            alt={"Click Soluções Inteligentes"}
                            className={classes.logo}
                            src={compactLogo}
                        />
                    </Hidden>
                    <div style={{ textAlign: 'right' }}>
                        <Button
                            startIcon={<HeadsetIcon />}
                            color="secondary"
                            onClick={props.openSupportDialog}
                            style={{marginBottom: '5px', marginRight: '5px'}}
                        >
                            Suporte
                        </Button>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}><SearchIcon /></div>
                            <InputBase
                                placeholder="Downloads…"
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                value={searchState.text}
                                onChange={handleSearchChange}
                                onKeyPress={handleSearchEnter}
                                inputProps={{ 'aria-label': 'downloads' }}
                            />
                        </div>
                    </div>
                </Container>
            </Toolbar>
        </AppBar>
        <SupportDialog
            open={props.supportDialogOpenned}
            close={props.closeSupportDialog}
            filials={props.filialSupport}
        />
        <DownloadDialog
            links={props.downloadLinks}
            search={searchState.search}
            close={() => setSearchState(old => ({
                ...old,
                open: false
            }))}
            open={searchState.open}
        />
        
    </div>
    )
}

export default Navbar